/*.slices__title-and-text {
	.block-img {
		width: 100%;

		img {
			width: inherit;
			border: solid red 1px;
		}
	}
}*/

.custom-page {
  margin-top: 0 !important;

  .section-header {
    position: relative;
    width: 100vw;
    left: -6vw;
    padding: 32px 0 32px 6vw;
    background: #f0f0f0;
  }
}
