.hero-alert-banner p {
  margin-bottom: 0 !important;
}

.hero-alert-banner a {
  position: relative;
  text-transform: uppercase;
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background: @PrimaryDark;
  }
}
