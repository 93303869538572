// classes.less
// Predefined classes used throughout the project

.wrapper {
  padding: 0 @BasePadding;
}

.primary-red {
  color: @PrimaryColor;

  &.blue {
    color: @SecondaryColor;
  }
}

.price {
  font-size: @fontLarge;
  font-weight: 900;
  color: @PrimaryDark;
}

.bg-blue {
  background: @SecondaryColor!important;
}

span.date {
  color: @SecondaryDark;
}
