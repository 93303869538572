@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  // outline: none !important;
  scroll-behavior: smooth;
  max-width: 100%;
  &:active {
    // outline: none;
  }
}

body,
html {
  min-height: 100%;
  font-size: 16px;
  overflow-x: hidden;
}

body {
  height: 100%;
  overflow-x: hidden;
}

#main {
  min-height: 90vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
header,
span,
strong,
b,
div,
blockquote,
a,
select,
input,
textarea,
option,
label {
  font-family: 'Jost', sans-serif;
}

a {
  text-decoration: none;
  color: @PrimaryColor;
  font-weight: 600;

  &:hover {
    color: darken(@PrimaryColor, 10%);
  }
  &.secondary {
    color: @SecondaryColor;
    &:hover {
      color: darken(@SecondaryColor, 10%);
    }
  }
}

.alert-banner .prismic-text p a {
  text-decoration: underline;
  transition: all 300ms ease;

  &:hover {
    color: rgba(90, 90, 90, 0.8);
  }
}

p,
span.text {
  font-size: @fontRegular;
  letter-spacing: 0.03em;
  line-height: 1.6em;

  @media screen and (max-width: 996px) {
    font-size: 14px;
  }
}

p {
  color: @ParagraphColor;

  &.secondary {
    a {
      color: @SecondaryColor;
    }
  }
}

input {
  caret-color: @PrimaryColor;
}

::selection {
  background: @PrimaryColor;
  color: white;
}

input:not(#search):not(.pcc-button),
textarea,
select,
option {
  border: solid 1px #707070;
  border-radius: 4px;
  padding: 18px 20px;
  // outline: none !important;

  &:active,
  &:focus {
    box-shadow: 0px 0px 4px rgba(67, 146, 185, 0.5);
  }
}

textarea {
  resize: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #555555 !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('../../assets//Media/icons/chevron-down.svg');
  background-repeat: no-repeat;
  background-size: 7.5%;
  background-position-x: 97.5%;
  background-position-y: 50%;
  cursor: pointer;

  option {
    cursor: pointer;
  }
}

input[type='radio'] {
  height: 20px;
  width: 20px;
}

figure {
  margin: 0;
}

img {
  vertical-align: initial;
  border-style: none;
}

ul:not(.noStyle) {
  list-style: none;

  li {
    display: flex;
    margin-bottom: 6px;
    font-size: @fontRegular;
    letter-spacing: @LetterSpacing;
    color: @ParagraphColor;

    &:before {
      content: '';
      display: flex;
      position: relative;
      width: 10px;
      min-width: 10px;
      max-width: 10px;
      height: 2px;
      left: 0;
      top: 0.5em;
      margin-right: 12.5px;
      background: @PrimaryColor;
    }
  }
}

.fm {
  &-red,
  &-red-italic,
  &-red-bold,
  &-red-italic-bold {
    color: @AlertColor;
  }
  &-bold {
    font-weight: 700;
  }

  &-italic,
  &-red-italic {
    font-style: italic;

    &-bold {
      font-weight: 700;
    }
  }

  &-underline {
    text-decoration: underline;

    &_bold {
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &-list-item {
    display: flex;
    // margin-bottom: 6px;
    font-size: @fontRegular;
    letter-spacing: @LetterSpacing;
    color: @ParagraphColor;

    &:before {
      content: '';
      display: flex;
      position: relative;
      width: 10px;
      min-width: 10px;
      max-width: 10px;
      height: 2px;
      left: 0;
      top: 0.5em;
      margin-right: 12.5px;
      background: @PrimaryColor;
    }
  }

  &-plan {
    + .fm-list-item,
    br + .fm-list-item {
      margin-top: -1.3rem;
    }
    + .fm-list-item,
    br + .fm-sub-list-item {
      position: relative;
      bottom: 25px;
      padding-left: 40px;
      &:before {
        content: '-';
        padding-right: 0.5rem;
        color: @PrimaryColor;
        font-size: 1.3rem;
      }
    }
  }

  &-heading-3 {
    margin: 0;
    padding: 1rem 0 0.5rem 0;
    display: block;
    color: @PrimaryDark;
    font-size: @fontTab;
    font-weight: 500;
    text-transform: uppercase;
  }

  &-heading-4 {
    margin: 0;
    padding: 1rem 0 0.5rem 0;
    display: block;
    color: @SecondaryDark;
    font-size: @fontMedium;
    font-weight: 500;
    text-transform: uppercase;
  }
}
