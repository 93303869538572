.form-input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 0px;

  label {
    color: @ParagraphColor;
    letter-spacing: 0.3px;
    font-weight: 500;
    margin-left: 12px;
    padding: 0 8px;
    background: white;
    transform: translateY(-50%);
    font-size: @fontRegular;
  }

  input,
  select,
  textarea,
  option {
    font-size: 1.125rem;
    font-weight: 300;
    color: @ParagraphColor;
    transition: all 300ms ease;
  }

  select,
  option {
    padding: 17px 20px;
  }

  &.error {
    input,
    textarea {
      border-color: @PrimaryColor!important;
    }
  }

  .alert {
    font-size: @fontSmall;
    color: @PrimaryColor;
    margin-left: 20px;
    margin-top: 8px;
    font-weight: 500;
  }

  .checkmark-container {
    height: 20px;
    display: block;
    position: relative;
    padding-left: 20px;
    margin-right: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      z-index: 100;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: transparent;
      border: solid 1px #707070;
      border-radius: 4px;
      transition: all 300ms ease-out;
    }

    &:hover input ~ .checkmark,
    &:active input ~ .checkmark,
    &:focus input ~ .checkmark,
    input:hover ~ .checkmark,
    input:active ~ .checkmark,
    input:focus ~ .checkmark {
      border-color: @PrimaryLighten;
    }

    input:checked ~ .checkmark {
      background: @PrimaryColor;
      border-color: @PrimaryColor;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 6.5px;
      top: 2px;
      width: 3px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .radio-container {
    height: 20px;
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      z-index: 100;
    }

    .radio-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: transparent;
      border: solid 1px #707070;
      border-radius: 100px;
      transition: all 300ms ease-out;

      &:after {
        content: '';
        position: absolute;
        height: 14px;
        width: 14px;
        margin: 2px;
        border-radius: 100px;
        background: transparent;
        transition: all 300ms ease;
      }
    }

    &:hover input ~ .radio-mark {
      box-shadow: 0px 0px 4px rgba(67, 146, 185, 0.5);
    }

    input:checked ~ .radio-mark {
      &:after {
        background: @PrimaryColor;
      }
    }
  }

  &.checkbox {
    flex-direction: column;

    .checkbox-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        position: relative;
        margin-left: 0;
        padding: 0;
        transform: none;
      }
    }

    input {
      margin-right: 15px;
    }
  }
}

.error-container {
  > * {
    color: @AlertColor;
  }
}
