// section-header.less
// Style for the header with filters over sections
.section-header {
  .filter-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    @media all and (min-width: 768px) {
      align-items: flex-end;
      flex-direction: row;
    }

    .main_item {
      margin-right: 42px;
      white-space: nowrap;
      @media all and (max-width: 767px) {
        width: 100%;
      }
    }

    .filter_items {
      display: flex;
      margin-top: 10px;
      white-space: nowrap;

      @media all and (min-width: 768px) {
        margin-top: 0;
      }
    }

    .filter_item {
      position: relative;
      margin-right: 30px;
      opacity: 0.5;
      text-transform: uppercase;
      transition: all 300ms;
      cursor: pointer;

      &.active {
        opacity: 1;
        &:after {
          width: 100%;
        }
      }

      &:hover {
        color: @PrimaryColor!important;
        opacity: 1;

        &:after {
          width: 100%;
        }
      }

      &:after {
        content: '';
        position: absolute;
        height: 3px;
        width: 0%;
        left: 0;
        top: 100%;
        border-radius: 100px;
        background: @PrimaryColor;
        transition: width 150ms 150ms ease-out;
      }
    }
  }

  p {
    font-weight: 400;
    color: @ParagraphColor;
    line-height: 30px;
    letter-spacing: 0.3px;
    padding-right: 15%;
    margin-top: 24px;
  }
}
