// actions-footer.less
// used for the actions at the bottom of courses and registration
.more-info {
  padding-top: 75px;
  padding-bottom: 90px;

  &__content {
    padding-right: 0;

    @media all and (min-width: 768px) {
      padding-right: 50px;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;

    en-course-rating {
      margin-bottom: 60px;
    }
  }
}

.more-courses {
  background: #f0f0f0;
  padding: 90px 0px;
}
