@font-face {
  font-family: Cooper Hewitt;
  font-weight: 400;
  src: url('../../assets/fonts/CooperHewitt-Book.otf') format('opentype');
}

@font-face {
  font-family: Cooper Hewitt;
  font-weight: 700;
  src: url('../../assets/fonts/CooperHewitt-Bold.otf') format('opentype');
}

@font-face {
  font-family: Cooper Hewitt;
  font-weight: 100;
  src: url('../../assets/fonts/CooperHewitt-Thin.otf') format('opentype');
}

@font-face {
  font-family: Cooper Hewitt;
  font-weight: 300;
  src: url('../../assets/fonts/CooperHewitt-Light.otf') format('opentype');
}

@font-face {
  font-family: Cooper Hewitt;
  font-weight: 600;
  src: url('../../assets/fonts/CooperHewitt-Semibold.otf') format('opentype');
}

@font-face {
  font-family: Cooper Hewitt;
  font-weight: 900;
  src: url('../../assets/fonts/CooperHewitt-Heavy.otf') format('opentype');
}

@font-face {
  font-family: Cooper Hewitt;
  font-weight: 500;
  src: url('../../assets/fonts/CooperHewitt-Medium.otf') format('opentype');
}
