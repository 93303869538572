@import '../colors.less';
@import '../fonts.less';

body {
  .bs-datepicker {
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.07);
    border-radius: 6px;

    .bs-datepicker-container {
      padding: 0px;
      border-radius: 6px;
    }

    > * {
      font-family: Cooper Hewitt, 'Segoe UI', Tahoma, Geneva, Verdana,
        sans-serif !important;
    }
  }
}

.theme-green .bs-datepicker-head {
  background-color: @PrimaryColor;
}

.theme-green .bs-datepicker-body table td.week span {
  color: @PrimaryColor;
}

.theme-green .bs-datepicker-body table td span.selected {
  background-color: @PrimaryColor;
}
