.trs-base {
  transition: all 0.5s;
  transition-delay: 0.4s;
  transition-timing-function: ease;
}

.trs-hero-img {
  transition: all 500ms;
  transition-delay: 0.5s;
  transition-timing-function: ease;
}

.trs-hero-content {
  transition: all 500ms;
  transition-delay: 0.4s;
  transition-timing-function: ease;
}

.trs-short-delay {
  transition: all 500ms;
  transition-delay: 0.2s;
  transition-timing-function: ease;
}

.trs-longer-delay {
  transition: all 500ms;
  transition-delay: 1s;
  transition-timing-function: ease;
}
